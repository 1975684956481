import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { formatMoney } from "../../../../helpers/formatters";

import "./style.scss";

const propTypes = {
  service: PropTypes.object,
  providerName: PropTypes.string,
};

const BasicInfo = ({ service, providerName }) => {
  const { t } = useTranslation();
  const mainPhone = service?.MainPhone?.FullPhoneNumberLocalised?.replace(
    "+81",
    "0"
  )
    .replace(" (", "")
    .replace(")", "")
    .replace(" ", "");

  const formatPhone = (phone) => {
    let formatted = phone;
    if (phone.length === 12) {
      formatted =
        phone.slice(0, 4) + "-" + phone.slice(4, 8) + "-" + phone.slice(8);
    } else if (phone.length === 11) {
      formatted =
        phone.slice(0, 3) + "-" + phone.slice(3, 7) + "-" + phone.slice(7);
    } else {
      formatted =
        phone.slice(0, 3) + "-" + phone.slice(3, 6) + "-" + phone.slice(6);
    }

    return formatted.replace(/\+/g, "");
  };

  return (
    <section>
      <div className="infoSection">
        <div className="infoItem">{t("name")}</div>
        <div className="infoItem">{providerName || service.Name}</div>
      </div>
      <div className="infoSection">
        <div className="infoItem">{t("price")}</div>
        <div className="infoItem">
          {service.Availability.Calendar.LowestRate &&
            `¥${formatMoney(service.Availability.Calendar.LowestRate)}`}
        </div>
      </div>
      <div className="infoSection">
        <div className="infoItem">{t("address")}</div>
        <div className="infoItem">
          {service.PhysicalAddress.PostCode} {service.PhysicalAddress.State}
          {service.PhysicalAddress.City} {service.PhysicalAddress.Line2}
          {service.PhysicalAddress.Line1}
        </div>
      </div>
      <div className="infoSection">
        <div className="infoItem">{t("phone")}</div>
        <div className="infoItem">
          {service.MainPhone.FullPhoneNumberLocalised
            ? formatPhone(mainPhone)
            : "-"}
        </div>
      </div>
      <div className="infoSection">
        <div className="infoItem">{t("website")}</div>
        <div className="infoItem">
          {(
            <a
              style={{ textDecoration: "underline" }}
              target="_blank"
              href={
                service.Website.includes("https") ||
                service.Website.includes("http")
                  ? service.Website
                  : `//${service.Website}`
              }
              rel="noreferrer"
            >
              {service.Website}
            </a>
          ) || "No Public Website"}
        </div>
      </div>
      <div className="infoSection">
        <div className="infoItem">{t("email")}</div>
        <div className="infoItem">
          {service.PublicEmail ? (
            <a
              style={{ textDecoration: "underline" }}
              href={`mailto:${service.PublicEmail}`}
            >
              {service.PublicEmail}
            </a>
          ) : (
            "-"
          )}
        </div>
      </div>
    </section>
  );
};

BasicInfo.propTypes = propTypes;

export default BasicInfo;
