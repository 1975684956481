import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { i18n } from "./lang/i18n";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

function App() {
  const [language, setLanguage] = useState("en");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const locale = searchParams.get("lang") || "en";

    i18n.changeLanguage(locale);
    setLanguage(locale);
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    searchParams.set("lang", lang);
    setSearchParams(searchParams);
  };

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div className={`App ${scrollTop === 0 ? "scrollTop" : ""}`}>
      {/* <Navigation lang={language} /> */}
      <Outlet context={[changeLanguage, language]} />
      {/* <Cart language={language} changeLanguage={changeLanguage} /> */}
      <ToastContainer />
      {/* <Footer language={language} changeLanguage={changeLanguage} /> */}
    </div>
  );
}

export default App;
