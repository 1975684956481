export const TRANSLATION_TW = {
  search: "搜尋",
  search_filter: "搜尋",
  search_page: "搜尋",
  all_breadcrumb: "全部",
  booking: "預訂",
  not_found: "抱歉，目前沒有任何商品可供顯示。請稍後再訪問此頁面。😉",
  all_categories: "所有類別",
  all_categories_shop: "所有類別",
  accommodation: "住宿",
  activity: "活動",
  restaurant: "餐廳",
  produce: "產品",
  search_keyword: "輸入關鍵字",
  view_details: "查看詳情",
  basic_info: "基本資訊",
  map: "地圖",
  details: "詳細資訊",
  home: "首頁",
  list: "清單",
  name: "名稱",
  address: "地址",
  phone: "電話",
  website: "網站",
  email: "電子郵件",
  fax: "傳真",
  from: "价格从",
  price: "價格",
  date: "日期",
  rate: "評分",
  price_range: "價格範圍",
  keyword: "輸入關鍵字",
  available_products: "可用產品",
  available_products_activity: "可用產品",
  available_products_goods: "可用產品",
  book_now: "立即預訂",
  book_now_activ: "立即預訂",
  book_now_goods: "立即預訂",
  check_price: "查看價格與可用性",
  check_price_activ: "查看價格與可用性",
  check_price_goods: "查看價格與可用性",
  cart_empty: "購物車是空的",
  first_name: "名字",
  last_name: "姓氏",
  re_email: "重新輸入電子郵件地址",
  mobile: "手機號碼",
  city: "城市",
  state: "州/省",
  country: "國家",
  zip: "郵遞區號",
  type_keywords: "輸入關鍵字",
  product: "產品",
  options: "選項/成本",
  totals: "總計",
  special_requests: "特殊要求",
  quick_booking: "快速預訂",
  request_book: "現場支付",
  customer_detail: "客戶詳情",
  load_more: "載入更多",
  request_to_book: "現場支付",
  start_time: "開始時間: {{ time }}",
  continue_payment: "繼續支付",
  close: "關閉",
  quantity: "數量",
  see_more: "查看更多",
  see_less: "少看",
  modal_header: "提出預訂請求",
  modal_desc:
    "請注意，此預訂需要與運營商確認。<br><br>您將在 24 小時內收到一封電子郵件，確認您的預訂是否已被接受，如果是，您可以在線安全支付。",
  confirm: "確認",
  submit: "提交",
  items: "項目",
  go_back: "返回",
  booking_req_detail_wait: "預訂請求詳情 - 等待確認",
  booking_req_instructions:
    "您將在 24 小時內收到一封電子郵件，確認運營商是否接受了您的預訂請求。 在您的預訂請求得到確認之前，無需支付任何費用。",
  request_date: "請求日期 (UTC)",
  booking_reference: "預訂參考",
  booked_by: "預訂者",
  supplier_information: "供應商資訊",
  total_gst: "總額 (含 GST)",
  amount_paid: "已支付金額 (含 GST)",
  amount_owing: "應付金額 (含 GST)",
  adult: "成人",
  check_in: "入住",
  check_out: "退房",
  not_found_page: "未找到",
  booking_date: "預訂日期",
  back_to_booking: "返回預訂頁面",
  sort_by: "排序方式",
  name_ascending: "名稱升序",
  name_descending: "名稱降序",
  rate_ascending: "評分升序",
  rate_descending: "評分降序",
  not_available: "不可用",
  not_available_activ: "不可用",
  not_available_goods: "不可用",
  price_tax: "價格 (含稅)",
  date_placeholder: "月/日/年",
  shopping: "購物",
  item_added: "商品已加入購物車",
  warning_dist: "請先完成購買再添加來自另一家分銷商的商品",
  clear_date: "清除日期",
  adults: "成人",
  seniors: "老年人",
  children: "兒童",
  duration: "持續時間",
  adults_count: "成人數: ",
  seniors_count: "老年人數: ",
  children_count: "兒童數: ",
  check_in_date: "入住: {{ date }}",
  check_out_date: "退房: {{ date }}",
  extra_options: "額外選項",
  minutes: "分鐘",
  hours: "小時",
  shuzenji: "修善寺地區地圖",
  trip_history: "行程歷史",
  instructions: "說明/常見問題",
  change_area: "更改地區",
  terms: "使用條款",
  app_provider: "應用程式提供者",
  login_details: "登錄詳情",
  book_now_oto: "立即預訂",
  add_wishlist: "加入願望清單",
  remove_wishlist: "從願望清單中移除",
  wishlists: "願望清單",
  wishlist_empty: "願望清單是空的",
  check_availability: "檢查可用性",
  add_to_reservation: "添加到預訂列表",
  language: "語言",
  experience_reservation: "體驗預訂",
  category: "類別",
  reset_filter: "重置篩選",
  about_okayama: "關於岡山",
  inside_scoop: "內幕消息",
  specialties: "特別報道",
  travel_genre: "觀光類型",
  lodging: "住宿和預訂",
  information: "資訊",
  okatabi: "文章",
  reservation: "Reservation",
  history: "歷史",
  nature: "自然",
  townscape: "城市風光",
  cherry: "櫻花",
  autumn: "秋葉",
  art: "藝術",
  experience: "體驗",
  gourmet: "美食",
  onsen: "溫泉",
  souvenirs: "紀念品",
  tourist_info: "旅遊資訊中心",
  model_course: "示範課程",
  event: "活動",
  access: "訪問",
  access_okayama: "岡山縣內訪問",
  faq: "常見問題解答",
  national_govt: "國家政府許可的導遊翻譯員",
  okayama_prefect: "岡山縣外國遊客接待委員會",
  safety_info: "旅行安全資訊和連結",
  time_lapse: "延時攝影",
  brochure: "旅遊手冊",
  privacy_policy: "隱私政策",
  product_items: "結果",
  product_items_jp: "",
  keyword_plc: "插入關鍵字",
};
