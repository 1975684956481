export const TRANSLATION_KR = {
  shopping: "쇼핑",
  about_okayama: "오카야마 소개",
  inside_scoop: "내부 소식",
  specialties: "특집",
  travel_genre: "관광 장르",
  lodging: "숙박 및 예약",
  information: "인포메이션",
  okatabi: "기사",
  reservation: "Reservation",
  history: "역사",
  nature: "자연",
  townscape: "도시 풍경",
  cherry: "벚꽃",
  autumn: "가을 잎",
  art: "미술",
  experience: "체험",
  gourmet: "미식",
  onsen: "온천",
  souvenirs: "기념품",
  tourist_info: "관광 안내 센터",
  model_course: "모델 코스",
  event: "이벤트",
  access: "접속",
  access_okayama: "오카야마 현내 접속",
  faq: "자주 묻는 질문",
  national_govt: "국가 정부 승인 가이드 통역사",
  okayama_prefect: "오카야마 현외국 관광객 수용 협의회",
  safety_info: "여행 및 링크 안전 정보",
  time_lapse: "타임 랩스 사진",
  brochure: "관광 안내 책자",
  privacy_policy: "개인 정보 보호 정책",
};
