export const TRANSLATION_CN = {
  search: "搜索",
  search_filter: "搜索",
  search_page: "搜索",
  all_breadcrumb: "全部",
  booking: "预订",
  not_found: "抱歉，没有产品显示。请稍后再访问此页面。😉",
  all_categories: "所有类别",
  all_categories_shop: "所有类别",
  accommodation: "住宿",
  activity: "活动",
  restaurant: "餐厅",
  produce: "产品",
  search_keyword: "输入关键词",
  view_details: "查看",
  basic_info: "基本信息",
  map: "地图",
  details: "详情",
  home: "首页",
  list: "列表",
  name: "名称",
  address: "地址",
  phone: "电话",
  website: "网站",
  email: "电子邮件",
  fax: "传真",
  from: "价格从",
  price: "价格",
  date: "日期",
  rate: "评分",
  price_range: "价格范围",
  keyword: "输入关键词",
  available_products: "可用产品",
  available_products_activity: "可用产品",
  available_products_goods: "可用产品",
  book_now: "立即预订",
  book_now_activ: "立即预订",
  book_now_goods: "立即预订",
  check_price: "查看价格和可用性",
  check_price_activ: "查看价格和可用性",
  check_price_goods: "查看价格和可用性",
  cart_empty: "购物车是空的",
  first_name: "名字",
  last_name: "姓氏",
  re_email: "重新输入电子邮件地址",
  mobile: "手机号码",
  city: "城市",
  state: "州/省",
  country: "国家",
  zip: "邮政编码",
  type_keywords: "输入关键词",
  product: "产品",
  options: "选项/成本",
  totals: "总计",
  special_requests: "特殊要求",
  quick_booking: "快速预订",
  request_book: "现场支付",
  customer_detail: "顾客详情",
  load_more: "加载更多",
  request_to_book: "现场支付",
  start_time: "开始时间: {{ time }}",
  continue_payment: "继续支付",
  close: "关闭",
  quantity: "数量",
  see_more: "查看更多",
  see_less: "少看",
  modal_header: "进行预订请求",
  modal_desc:
    "请注意，此预订需要与运营商确认。<br><br>您将在 24 小时内收到一封电子邮件，确认您的预订是否已被接受，如果是，您可以在网上安全支付。",
  confirm: "确认",
  submit: "提交",
  items: "商品",
  go_back: "返回",
  booking_req_detail_wait: "预订请求详细信息 - 等待确认",
  booking_req_instructions:
    "您将在 24 小时内收到一封电子邮件，确认运营商是否接受了您的预订请求。 在您的预订请求得到确认之前，无需支付任何费用。",
  request_date: "请求日期 (UTC)",
  booking_reference: "预订参考",
  booked_by: "预订人",
  supplier_information: "供应商信息",
  total_gst: "总额 (含 GST)",
  amount_paid: "已支付金额 (含 GST)",
  amount_owing: "应付金额 (含 GST)",
  adult: "成人",
  check_in: "入住",
  check_out: "退房",
  not_found_page: "未找到",
  booking_date: "预订日期",
  back_to_booking: "返回预订页面",
  sort_by: "排序方式",
  name_ascending: "名称升序",
  name_descending: "名称降序",
  rate_ascending: "评分升序",
  rate_descending: "评分降序",
  not_available: "不可用",
  not_available_activ: "不可用",
  not_available_goods: "不可用",
  price_tax: "价格 (含税)",
  date_placeholder: "月/日/年",
  shopping: "购物",
  item_added: "商品已添加到购物车",
  warning_dist: "请在添加另一家经销商的商品之前完成购买",
  clear_date: "清除日期",
  adults: "成人",
  seniors: "老年人",
  children: "儿童",
  duration: "持续时间",
  adults_count: "成人数: ",
  seniors_count: "老年人数: ",
  children_count: "儿童数: ",
  check_in_date: "入住: {{ date }}",
  check_out_date: "退房: {{ date }}",
  extra_options: "额外选项",
  minutes: "分钟",
  hours: "小时",
  shuzenji: "修善寺地区地图",
  trip_history: "行程历史",
  instructions: "说明/常见问题",
  change_area: "更改地区",
  terms: "使用条款",
  app_provider: "应用提供商",
  login_details: "登录详情",
  book_now_oto: "立即预订",
  add_wishlist: "添加到愿望清单",
  remove_wishlist: "从愿望清单中移除",
  wishlists: "愿望清单",
  wishlist_empty: "愿望清单为空",
  check_availability: "检查可用性",
  add_to_reservation: "添加到预订列表",
  language: "语言",
  experience_reservation: "体验预订",
  category: "类别",
  reset_filter: "重置筛选",
  about_okayama: "关于冈山",
  inside_scoop: "内幕消息",
  specialties: "特别报道",
  travel_genre: "观光类",
  lodging: "住宿和预订",
  information: "信息",
  okatabi: "文章",
  reservation: "预约",
  history: "历史",
  nature: "自然",
  townscape: "城市风光",
  cherry: "樱花",
  autumn: "秋叶",
  art: "艺术",
  experience: "体验",
  gourmet: "美食",
  onsen: "温泉",
  souvenirs: "纪念品",
  tourist_info: "旅游信息中心",
  model_course: "示范课程",
  event: "活动",
  access: "访问",
  access_okayama: "冈山县内访问",
  faq: "常见问题解答",
  national_govt: "国家政府许可的导游译员",
  okayama_prefect: "冈山县外国游客接待委员会",
  safety_info: "旅行安全信息和链接",
  time_lapse: "延时摄影",
  brochure: "旅游手册",
  privacy_policy: "隐私政策",
  product_items: "结果",
  product_items_jp: "",
  keyword_plc: "插入关键字",
};
