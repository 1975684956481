export const TRANSLATION_THAI = {
  shopping: "ช้อปปิ้ง",
  about_okayama: "เกี่ยวกับโอคายาม่า",
  inside_scoop: "ข่าวลือลับ",
  specialties: "คุณสมบัติพิเศษ",
  travel_genre: "รูปแบบการท่องเที่ยว",
  lodging: "ที่พักและการจองห้องพัก",
  information: "ข้อมูล",
  okatabi: "บทความ",
  reservation: "Reservation",
  history: "ประวัติศาสตร์",
  nature: "ธรรมชาติ",
  townscape: "ทิวทัศน์เมือง",
  cherry: "ดอกเซอร์รี่",
  autumn: "ใบไม้ร่วง",
  art: "ศิลปะ",
  experience: "ประสบการณ์",
  gourmet: "อาหารอร่อย",
  onsen: "ออนเซ็น (น้ำพุร้อน)",
  souvenirs: "ของฝาก",
  tourist_info: "ศูนย์ข้อมูลนักท่องเที่ยว",
  model_course: "หลักสูตรแบบจำลอง",
  event: "เหตุการณ์",
  access: "เข้าถึง",
  access_okayama: "เข้าถึงภายในจังหวัดโอคายามา",
  faq: "คำถามที่พบบ่อย",
  national_govt: "นักแปลและสำนักงานภาครัฐที่ได้รับอนุญาตจากรัฐบาลแห่งชาติ",
  okayama_prefect:
    "คณะกรรมการการต้อนรับนักท่องเที่ยวต่างชาติของจังหวัดโอคายามา",
  safety_info: "ข้อมูลความปลอดภัยสำหรับการเดินทางและลิงก์",
  time_lapse: "การถ่ายภาพแบบไทม์แลปส์",
  brochure: "โบรชัวร์นักท่องเที่ยว",
  privacy_policy: "นโยบายความเป็นส่วนตัว",
};
