import moment from "moment";

export const isValidHex = (color) => {
    // Check if the color has the `#`, if not, prepend it
    const formattedColor = color.startsWith("#") ? color : `#${color}`;

    // Regex to check for 3 or 6 digit hex codes
    const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
    return hexRegex.test(formattedColor) ? formattedColor : null;
};


export const doesEventExistOnDate = (date, dailyRates) => {
    return dailyRates.some((event) => {
        return event.holiday && moment(event.start).isSame(date, "day");
    });
};
